<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Footer</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <div class="mtb-20">
                  <p>footer.css import link</p>
                  <div class="gray-250 p-10 w800">
                    <div class="flex align-center justify-between">
                      <p>{{ this.cssLink }}</p>
                      <a class="pointer white p-10" @click="copyToClipboard()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-copy"
                        >
                          <rect
                            x="9"
                            y="9"
                            width="13"
                            height="13"
                            rx="2"
                            ry="2"
                          ></rect>
                          <path
                            d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="divider"></div>

            <section id="default-footer">
              <div class="content-band">
                <div class="flex">
                  <h3>Default Footer</h3>
                  <router-link
                    to="/design-system/building-blocks/blocks/footer-component"
                    class="ml-10 hover-font-white hover-black border-500 rounded-4 pointer"
                    ><div class="plr-20 p-5">Demo</div></router-link
                  >
                </div>
                <CodeView component="footer" type="footer"
                  ><img src="../../../assets/footer.png" />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Footer",
  components: {
    TempLeftNav,
    CodeView,
    SubFooter,
    StickyTOC,
  },
  data: function() {
    return {
      cssLink: `<link rel="stylesheet" href="https://ds-stg.iop.ohio.gov/src/blocks/footer/footer.css" type="text/css">`,
    };
  },
  methods: {
    copyToClipboard: function() {
      let codeToCopy = this.cssLink;
      this.$copyText(codeToCopy).then(
        function() {
          console.log("Copied");
        },
        function() {
          console.warn("Can not copy");
        }
      );
    },
  },
};
</script>
